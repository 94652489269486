import { fetchBongeoTargeting, fetchGeoTargeting, fetchElmerTargeting, fetchCategoryTargeting } from "./targeting-handler";
import getContextConfig from "./get-context-config";
import validateKeyValue from "./validate-key-value-targetings";
import { onUrlUpdate } from "./url-updater";
import logger from "./logger";
import { resetSlotNumberTargeting } from "./get-slot-number";
import { hasAudienceConsent } from "./consent";
import { getCookie } from "./cookie-helper";

const contextWhitelist = ["device", "_ta_", "pageType", "articleId", "tags", "category", "isLoggedIn", "isPayingCustomer", "accountLevel", "abTest", "theProgram", "ppid", "app"];

const siteConfig = getContextConfig();

function setPageTargetings() {
  for (const [key, value] of Object.entries(siteConfig)) {
    if (contextWhitelist.includes(key)) {
      if (!validateKeyValue(key, value)) {
        console.warn(`invalid targeting key/value: ${key}/${value}`);
        continue;
      }
      logger("Setting page targeting", key, value);
      googletag.pubads().setTargeting(key, value);
    }
  }
}

async function setupPageTargetings() {
  // Fetch all targetings that require external requests
  const requiredPagePromises = [];
  const targetingTimeout = 300;

  if (hasAudienceConsent()) {
    requiredPagePromises.push(fetchElmerTargeting(targetingTimeout));

    const clientId = getCookie("bnacid");

    if (clientId) {
      googletag.cmd.push(() => {
        logger("Setting clientId", "rc", clientId);
        googletag.pubads().setTargeting("rc", clientId);
      })
    }
  }

  requiredPagePromises.push(fetchBongeoTargeting(targetingTimeout));
  requiredPagePromises.push(fetchCategoryTargeting(targetingTimeout));
  requiredPagePromises.push(fetchGeoTargeting(targetingTimeout));
  const requiredPageResponses = await Promise.allSettled(requiredPagePromises);

  requiredPageResponses.forEach((response) => {
    if (response.status === "rejected") {
      logger(response.reason);
    } else {
      const data = response.value;

      if (!data) {
        logger("No targeting data found");
        return;
      }

      googletag.cmd.push(() => {
        logger("Setting targetings", data);
        for(const [key, value] of Object.entries(data)) {
          googletag.pubads().setTargeting(key, value);
        }
      });
    }
  })

  // Page targetings that does not require any external requests
  googletag.cmd.push(() => {
    const url = location.hostname + location.pathname;
    const urlParts = url.replace(/-/g, "/").split("/").filter(Boolean);

    googletag.pubads().setTargeting("url", url);
    googletag.pubads().setTargeting("urlParts", urlParts);
    googletag.pubads().setTargeting("tcf", "1");

    logger("Setting page targetings", { url, urlParts });

    setPageTargetings();

    // If eventual query params are present, add them as page targetings, these are not validated
    const queryParams = new URLSearchParams(location.search);

    for (const [key, value] of queryParams.entries()) {
      logger("Parsing query params to page targeting", key, value);
      googletag.pubads().setTargeting(key, value);
    }

    onUrlUpdate(async (e) => {
      resetSlotNumberTargeting();
      const navigationUrl = new URL(e.destination ? e.destination.url : location.href);
      const url = navigationUrl.hostname + navigationUrl.pathname;
      const urlParts = url.replace(/-/g, "/").split("/").filter(Boolean);

      googletag.pubads().setTargeting("url", url);
      googletag.pubads().setTargeting("urlParts", urlParts);

      logger("Page targeting on url update", { url, urlParts });

      setPageTargetings();

      try {
        const data = await fetchCategoryTargeting(500);
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            googletag.pubads().setTargeting(key, value);
          }
        }
      } catch (e) {
        logger(e);
      }

      // if meta tag hostname is present, we do not want to fetch bam data again.
      if (document.querySelector("meta[name='bad:hostname']")) return;

      logger("Fetching bam data for new url", url);
      window.bamData = await fetch(`https://bam.bonad.io?url=${url}`).then((response) => response.json());
    });
  });
}

export { setupPageTargetings };
