import logger from "./logger.js"
const auctions = [];
let refreshTimeout;

export default (slot, data, cb) => {
  const adDiv = document.getElementById(slot.getSlotElementId());

  if (divInsideViewport(adDiv)) {
    runAuction(slot, data, cb);
  } else {
    auctions.push({ slot, data, cb });

    refreshTimeout = setTimeout(() => {
      while (auctions.length) {
        const { slot, data, cb } = auctions.shift();

        runAuction(slot, data, cb);
      }
    }, 10);
  }
};

function divInsideViewport(div) {
  return div.getBoundingClientRect().top < window.innerHeight && div.getBoundingClientRect().top + div.getBoundingClientRect().height > 0;
}

function runAuction(slot, data, cb) {
  window.pbjs = window.pbjs || { cmd: [] };

  window.pbjs.cmd.push(() => {
    const prebidTimeout = 1500; // Tests are gonna be run to see if we can make this more strict.
    let timedOut = false;

    const timeoutId = setTimeout(() => {
      logger("Prebid auction timed out for slot:" + slot.getSlotElementId() + "after " + prebidTimeout + "ms");
      timedOut = true;
      cb();
    }, prebidTimeout);

    window.pbjs.rp.requestBids({
      gptSlotObjects: [slot],
      data,
      callback: () => {
        logger("Prebid auction completed for slot:" + slot.getSlotElementId());
        clearTimeout(timeoutId);
        if (!timedOut) {
          slot.setTargeting("demandmanager", 1);
          cb();
        }
      },
    });
  });
};
