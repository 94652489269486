import { getCookie } from "./cookie-helper";
import logger from "./logger";

export const activateDeveloperConsole = () => {
    const urlParams = new URLSearchParams(window.location.search);

    logger("Activating developer console");

    const consoleValue = urlParams.get("bauConsole") || getCookie("bauConsole") || "true";

    // set badConsole cookie value to true or to the url param value & set max-age to 7 days
    document.cookie = `bauConsole=${urlParams.get("bauConsole") || true};path=/;max-age=604800`;

    const script = document.createElement("script");
    if (consoleValue === "true"){
      script.src = "https://assets.bonad.io/dfp/bau-console.js";
    } else if (consoleValue === "stage"){
      script.src = "https://assets.bonad.io/dfp/stage/bau-console.js";
    } else if (consoleValue === "local"){
      script.src = "https://localhost:8080/dist/bau-console.js";
    }
    document.head.appendChild(script);
};

// Expose for manual activation in browser
window.badConsole = () => {
    activateDeveloperConsole();
}

// Backwards compatibility for now.
window.Bau = {
  openConsole: () => {
    console.warn("bauConsole is deprecated and will be eventually removed, use window.badConsole() instead");
    activateDeveloperConsole();
  },
}