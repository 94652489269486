import { getCookie } from "./cookie-helper";
import logger from "./logger";

async function fetchElmerTargeting(timeout = 5000) {
	const clientId = getCookie("bnacid");
	if (!clientId) return;

	const url = `https://elmer.bonniernews.se/v1/segment/${clientId}?purpose=personalized-ads`;

	const options = {
		headers: {
			"x-bn-origin": `bad${window.location?.hostname !== 'localhost' ? `:${location.hostname}` : ''}`, //send the origin of the request for better elmer tracking.
		},
		signal: AbortSignal.timeout(timeout),
	};

	try {
		const response = await fetch(url, options);
		const data = await response.json();

		return { bi: data.marketing_segments };
	} catch (e) {
	  logger(e + " - Elmer targeting failed");
		return Promise.reject(e);
	}
}

async function fetchCategoryTargeting(timeout = 5000) {
	const articles = [...document.querySelectorAll("article")];
  if (articles.length > 0) {
		// If there are multiple articles on a page, we only want the ones with a uuid
    const articleId = articles.filter((article) => article.dataset.uuid).at(-1).dataset.uuid.split(".").at(-1);
    if (articleId) {
			try {
				const response = await fetch(`https://assets.bonad.io/categories/${articleId.substring(0, 3)}/${articleId}.json`, { signal: AbortSignal.timeout(timeout) });
				const data  = await response.json();

				return {
					categories: data.NITF
				};
			} catch (e) {
				logger(e + " - Category targeting failed");

				return Promise.reject(e);
			}
    }
  }
}

function appendCurrentPositionToUrl(baseUrl, callback) {
	if (window.navigator && window.navigator.geolocation && window.navigator.permissions) {
		window.navigator.permissions
			.query({ name: "geolocation" })
			.then((result) => {
				if (result.state === "granted") {
					window.navigator.geolocation.getCurrentPosition((position) => {
						const { latitude, longitude } = position.coords;
						baseUrl.searchParams.set("latitude", latitude);
						baseUrl.searchParams.set("longitude", longitude);
						return callback(baseUrl);
					});
				} else {
					return callback(baseUrl);
				}
			})
			.catch(() => {
				callback(baseUrl);
			});
	} else {
		return callback(baseUrl);
	}
}

async function fetchBongeoTargeting(timeout = 5000) {
	const url = new URL(process.env.BONGEO_URL);

	return new Promise((res, rej) => {
		appendCurrentPositionToUrl(url, async (url) => {
			try {
				const response = await fetch(url, { signal: AbortSignal.timeout(timeout) })
				const data = await response.json();

				res({
					bontarg: Object.values(data)
				})
			} catch (e) {
				logger(e + " - Bongeo targeting failed");

				rej(e);
			}
		})
	});
}

async function fetchGeoTargeting(timeout = 5000) {
	const url = new URL(process.env.CONTEXT_GEO_URL);
	return new Promise((res, rej) => {
		appendCurrentPositionToUrl(url, async (url) => {
			try {
				const response = await fetch(url, { signal: AbortSignal.timeout(timeout) });
				const data = await response.json();

				res({
					bongeo: Object.values(data)
				})
			} catch (e) {
				logger(e + " - Geo targeting failed");

				rej(e);
			}
		});
	});
}

export { fetchBongeoTargeting, fetchGeoTargeting, fetchElmerTargeting, fetchCategoryTargeting };