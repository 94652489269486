const urlParams = new URLSearchParams(window.location.search);

function logger(msg, ...args) {
  if (urlParams.get("badDebug") === "true") {
    const logString = `BAD DEBUG: ${msg} %c${parseArgs(args).join(" ")}`;
    const timestampString = ` %c${performance.now().toFixed(2)}ms`;
    console.log(logString + timestampString, "color:rgb(255, 71, 71)",  "color: orange");
  }
}

function parseArgs(args) {
  return args.map((arg) => {
    if (typeof arg === "object") {
      return JSON.stringify(arg, null, 2);
    }
    if (Array.isArray(arg)) {
      return arg.join(", ");
    }
    return arg;
  });
}

export default logger;