import { hasPersonalizedAdsConsent } from "./consent";
import { getCookie } from "./cookie-helper";

const siteMetaConfig = {};

document.head.querySelectorAll("meta[name^='bad:']").forEach((metaTag) => {
  const key = metaTag.name.replace("bad:", "");
  siteMetaConfig[key] = metaTag.content;
});

function allowPersonalizedAds() {
  if (getCookie("npa") === "true") {
    return true;
  }

  return hasPersonalizedAdsConsent();
}

function getContextConfig() {
  const defaultSiteConfig = {
    pageType: "section",
    hostname: location.href,
    disablePersonalizedAds: !allowPersonalizedAds(),
    // adReporterUrl: process.env.AD_REPORTER_URL, // "https://au-ad-reporter.bonniernews.se" prod
    // metadataHostname: "assets.bonad.io", //Maybe this can be used for testing?
  };

  // Prioritize site meta config over default config
  return { ...defaultSiteConfig, ...siteMetaConfig };
}

export default getContextConfig;
