import logger from "../../helpers/logger";

function isInternalNetwork(cb) {
  fetch("https://assets.bonad.io/test-vpn")
    .then((response) => response.text())
    .then((text) => {
      if (text === "OK") {
        logger("Internal network detected");
        return cb(null, true);
      }
      cb(null, false);
    })
    .catch((err) => {
      cb(err);
    });
}

export {
  isInternalNetwork
};

